// $gradient-bg: linear-gradient( 94.3deg,  rgba(30,30,30,1) 10.9%, rgba(81,84,115,1) 87.1% );
// $sidebar-bg-color: $gradient-bg;
$sidebar-bg-color: #1d1d1d;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(29, 29, 29, 0.95);
$sidebar-color: #adadad;
$highlight-color: #d8d8d8;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

@import '~react-pro-sidebar/dist/scss/styles.scss';
body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: sans-serif;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  position: relative;
  
  .btn-toggle {
    cursor: pointer;
    display: none;
  }


  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .sidebar-btn {
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    
    header {
      margin-bottom: 16px;
      border-bottom: 1px solid #efefef;
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;
      a {
        text-decoration: none;
        color: #888;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
    > span {
      margin-left: 10px;
    }
  }

  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }
  }
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}